import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
function IssuePage({ issueData }) {
    const [bgColor, setBgColor] = useState("")
    const [bgColor1, setBgColor1] = useState("")
    useEffect(() => {
        const createdDate = new Date(issueData?.created_at).getTime()
        const currentDate = new Date().getTime()
        if (currentDate - createdDate < 604800000) {
            setBgColor("#00b140")
        }
        else if (currentDate - createdDate > 604800000 && currentDate - createdDate < 2592000000) {
            setBgColor("yellow")
        } else {
            setBgColor("pink")
        }
    }, [])

    useEffect(() => {
        const dueDate = new Date(issueData?.due_date).getTime()
        const currentDate = new Date().getTime()
        const diffDate = dueDate - currentDate
        if (diffDate > 604800000) {
            setBgColor1("#c5e1a5")
        } else if (diffDate > 259200000) {
            setBgColor1("#fff9c4 ")
        } else if (diffDate > 0) {
            setBgColor1("#ffe082")
        } 
        else{
            setBgColor1("#ffcdd2")
        }
    }, [issueData?.due_date])
    console.log("hello", new Date(issueData?.due_date).getTime() * 1000)
    console.log("new dtae", new Date(issueData?.created_at).getTime(), new Date().getTime())
    return (
        <tr>
            <td>
                <a target="_blank" href={issueData?.web_url} className="text-decoration-none" rel="noopener noreferrer">
                    <span className='break-word-data'>{issueData?.references?.short}</span>
                </a>
                {" " + issueData?.description.slice(0, 100) + "..."}
                {issueData?.labels?.map((value)=>(
                <button className='btn btn-primary m-1' style={{fontSize:"10px"}}>{value}</button>
                ))}
                <br />
                <span className='d-block d-lg-none'> <a target="_blank" className='text-decoration-none' href={`https://gitlab.redgrape.tech/${issueData?.references?.full.split("#")[0]}/-/issues?sort=updated_desc&state=opened`} rel="noopener noreferrer">
                    {issueData?.references?.full.split("#")[0]}
                </a></span>
            </td>

            <td className='d-none d-lg-block'>
                <a target="_blank" className='text-decoration-none' href={`https://gitlab.redgrape.tech/${issueData?.references?.full.split("#")[0]}/-/issues?sort=updated_desc&state=opened`} rel="noopener noreferrer">
                    {issueData?.references?.full.split("#")[0]}
                </a>
            </td>
            <td>{issueData?.milestone?.title}</td>
            <td style={{ backgroundColor: bgColor }}>{moment(issueData?.created_at).startOf('minutes').fromNow()}
            </td>
            {issueData?.due_date ?
                <td style={{ backgroundColor: bgColor1 }}>
                    {moment(new Date(issueData?.due_date)).startOf('minutes').fromNow()}
                </td>
                : <td>&nbsp;</td>
            }
        </tr>
    )
}

export default IssuePage