import './App.css';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import IssuePage from './IssuePage';
function App() {
  // const data = [1, 2, 3, 4, 5, 6, 7, 8]
  const [data, setData] = useState([])
  const [timer, setTimer] = useState(0)
  var mobile = window.matchMedia("(max-width: 992px)")
  const fetchData = async () => {
    //  const data={
    //   key:"rJxPg6wR8BhZK3TqCe2yWct3nHhWvgu3"
    //  }
    const formData = new FormData()
    formData.append("key", "rJxPg6wR8BhZK3TqCe2yWct3nHhWvgu3")
    const res = await axios.post("https://webutils.redgrape.tech/trackissues", formData)
    setData(res.data)
  }
  setInterval(() => {
    setTimer(timer + 1)
  }, 10000)

  useEffect(() => {
    fetchData()
  }, [timer])

  const user =Object.keys(data)
  return (
    <div className="App pt-5 d-flex flex-column justify-content-center">
      <h1 className='text-center my-3 mx-auto py-3 dashboard-header'>Gitlab issues Dashboard</h1>
      <div className='row mx-0 dashboard-body'>
        {user.map((value, index) => (
          <React.Fragment key={index}>
            <div className='col-12 px-0 mx-0 h-100 '>
              <div className={`h-100 ${mobile.matches ? "p-0" : "p-2"}`} >
                <Card className='p-2 h-100'>
                  <div className='row overflow-hidden mx-0'>
                    <div className='text-left gitlab-username text-uppercase py-2'>{data[value]?.[0]?.issues[0]?.assignee?.name ? data[value]?.[0]?.issues[0]?.assignee?.name : value}({data?.[value]?.[0]?.issues.length})</div>
                    <div className=''
                      style={{ overflowX: "scroll" }}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th className='d-none d-lg-block'>Project Name</th>
                            <th>Milestone</th>
                            <th>Create Date</th>
                            <th>Due Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.[value]?.[0]?.issues?.map((issueData, index) => (
                            <IssuePage issueData={issueData} />
                          ))}
                        </tbody>
                      </Table>
                    </div>

                  </div>
                </Card>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default App;
